<template>
    <v-container>
        <v-row>
            <v-breadcrumbs large :items="items_nav">
                <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    {{ item.text }}
                </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>
        <v-card elevation="4">
            <v-card-title>
                <v-row>
                    <div class="col">
                        Novo Tema
                    </div>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation @submit.prevent="submit()">
                  <v-row>
                    <v-col cols="12" md="4" >
                      <v-card elevation="4" height="160" width="160" @click="$refs.temaImage.$refs.input.click()" >
                        <v-container grid-list-sm fluid>
                          <v-layout row wrap>
                            <v-img
                              aspect-ratio="1"
                              
                            >              
                            <template v-slot:placeholder>
                            

                              <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                              v-if="!image_set"
                              >
                              
                              <v-file-input
                                v-model="image"
                                :rules="rulesImagem"
                                accept="image/png, image/jpeg"
                                hidden
                                style="display: none;"
                                ref="temaImage"
                                @change="upload()"
                            ></v-file-input>
                              <v-icon
                              large
                              center
                              v-text="'mdi-camera-plus'"
                              ></v-icon>
                              
                              </v-row>
                              <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                              v-else>
                              <img :src="imageUrl"/>
                              </v-row>
                            </template>        
                            </v-img>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="tema.titulo"
                                counter="50"
                                maxlength="50"
                                label="Título"
                                required
                            ></v-text-field>
                        </v-col>
                        
                    </v-row>
                  <v-row>
                    <v-combobox
                      v-model="tema.categorias"
                      :items="categorias"
                      chips
                      clearable
                      label="Categorias"
                      multiple
                      prepend-icon="mdi-filter-variant"
                      solo
                      item-value="id"
                      item-text="nome"
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item.nome }}</strong>&nbsp;§
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-row>
                
                </v-form>
            </v-card-text>
            <v-card-action class="d-flex flex-row-reverse">
                <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="submit()"
                            >
                            SALVAR
                        </v-btn>
                    </div>
            </v-card-action>
        </v-card>
        <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Realizando operação por favor aguarde...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogMessage" width="480">
          <v-card class="mx-auto" dark>
            <v-card-title>
                <span class="title font-weight-light">{{ message.title }}</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
                {{ message.body }}
            </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from 'uuid';
import database from "@/services/database";


export default {
    data() {
        return {
            items_nav: [
                {
                    text: "Sistema",
                    disabled: false,
                },
                {
                    text: "Temas",
                    disabled: true,
                },
                {
                    text: "Novo",
                    disabled: true,
                }
            ],
            rulesImagem: [
                value => !value || value.size < 2000000 || 'Tamanho da imagem deve ser inferior a 2 MB!',
            ],
            image_set: false,
            image: null,
            imageUrl: "",
            dialogProgress: false,
            dialogMessage: false,
            message: {
              title: "",
              body: ""
            },
            tema: {
              titulo: "",
              url: "",
              categorias: []
            },
            categorias: []
        }
    },
    computed: {
    ...mapGetters(["getTemas",]),
  },
  mounted() {
    this.loadCategorias();
  },
    methods: {
      loadCategorias: async function() {
        this.categorias = await database.getAllCategoriasConvites();
      },
      validate: async function() {
        
      },
      submit: async function() {
        
        try {
          this.dialogProgress = true;
          await database.addTema(this.tema);
          this.message.title = "Operação realizada";
          this.message.body = "Alteração realizada com sucesso!";
          this.dialogProgress = false;
          this.dialogMessage = true;
        } catch(error) {
          console.log(error);
          this.dialogProgress = false;
          this.message.title = "Operação falhou";
          this.message.body = "Verifique sua conexão e tente novamente!";
          this.dialogMessage = true;
        }
        


      },
      uploadImage: function(event) {
        this.$refs.categoriaImage.click();
      },
      saveImage: function(event) {
        this.image = event.target.files[0];
      },
      remove (item) {
        this.tema.categorias.splice(this.tema.categorias.indexOf(item), 1)
        this.tema.categorias = [...this.tema.categorias]
      },
      upload: async function() {
        const reader = new FileReader();
        this.dialogProgress = true;
        var imageToSave = null;
        reader.readAsDataURL(this.image);
        
        if(this.image == null) {
            return;
        }
        reader.onload = (e) => {
            imageToSave = e.target.result;
        };
        reader.onloadend = async () => await this.save(imageToSave);
        
      },
      save: async function(image) {
        var result = await database.uploadImagemTema(
            image,
            this.image.name.split(".")[1],
            uuidv4(),
            this.image.name.split(".")[0]
        );
        this.tema.url = result.url;
        this.image_set = true;
        this.imageUrl = URL.createObjectURL(this.image);
        this.dialogProgress = false;
      }
    },
}
</script>

<style>

</style>